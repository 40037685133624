
import React from "react"
import { Row, Col } from "react-bootstrap"

class Footer extends React.Component {
  constructor(props) {
    super(props);
  }

  render(){
    const isMobile = this.props.isMobile;
    var menuList = this.props.menus.map(function(menu){
      if (isMobile) {
        return(
          <a className="footer-link" href={menu.node.link}><p><small>{menu.node.name}</small></p></a>
        )
      }
      return(
        <a className="footer-link" href={menu.node.link}><h5>{menu.node.name}</h5></a>
      )
    })
    var socialList = this.props.socials.map(function(social){
      if (isMobile) {
        return(
          <a className="footer-link" href={social.link} target="_blank"><p><small>{social.name}</small></p></a>
        )
      }
      return(
        <a className="footer-link" href={social.link} target="_blank"><h5>{social.name}</h5></a>
      )
    })

    return(
      <Row className="footer mx-auto">
        <Col md={4} sm={12} lg={4} xl={4} xs={12} >
          {!isMobile && <a href="/" className="footer-link">
            <img  srcset={this.props.siteInfo.logoLarge.gatsbyImageData.images.sources[0].srcSet} width="60"
            style={{marginBottom:`15px`}}
            />
            <h5>{this.props.siteInfo.copyrightText}</h5>
            </a>
          }
        </Col>
        <Col md={1} sm={12} lg={1} xl={1} xs={12} ></Col>
        <Col md={2} sm={12} lg={2} xl={2} xs={12} >
          {menuList}
          {isMobile && <hr></hr>}
        </Col>
        <Col md={2} sm={12} lg={2} xl={2} xs={12} >
          {socialList}
          {isMobile && <hr></hr>}
        </Col>
        <Col md={3} sm={12} lg={3} xl={3} xs={12} >
          {
            isMobile &&
            <a href={this.props.siteInfo.addressLink} target="_blank" className="footer-link">
              <p><small>{this.props.siteInfo.address.address}</small></p>
            </a>
          }
          {
            isMobile &&
            <a href={"mailto:" + this.props.siteInfo.email} target="_blank" className="footer-link">
              <p><small>{this.props.siteInfo.email}</small></p>
            </a>
          }
          {
            isMobile && this.props.siteInfo.phone &&
            <a href={"https://brrrlabs.com/" + this.props.siteInfo.phone} target="_blank" className="footer-link">
              <p><small>{this.props.siteInfo.phone}</small></p>
            </a>
          }
          {isMobile && <hr></hr>}
          {isMobile &&
            <Row>
              <Col md={4} sm={4} lg={4} xl={4} xs={4}>
                <a href="/" className="footer-link">
                  <img  srcset={this.props.siteInfo.logoLarge.gatsbyImageData.images.sources[0].srcSet} width="60"
                  style={{marginBottom:`15px`}} />
                </a>
              </Col>
              <Col md={8} sm={8} lg={8} xl={8} xs={8}>
                <a href="/" className="footer-link">
                  <small>{this.props.siteInfo.copyrightText}</small>
                </a>
              </Col>
            </Row>

          }

          {
            !isMobile &&
            <a href={this.props.siteInfo.addressLink} target="_blank" className="footer-link">
              <h5>{this.props.siteInfo.address.address}</h5>
            </a>
          }
          {
            !isMobile && this.props.siteInfo.email &&
            <a href={"mailto:" + this.props.siteInfo.email} target="_blank" className="footer-link">
              <h5>{this.props.siteInfo.email}</h5>
            </a>
          }
          {
            !isMobile && this.props.siteInfo.phone &&
            <a href={"https://brrrlabs.com/" + this.props.siteInfo.phone} target="_blank" className="footer-link">
              <h5>{this.props.siteInfo.phone}</h5>
            </a>
          }
        </Col>
      </Row>
    )
  }
}

export default Footer
